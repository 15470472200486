@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@300;400;500;600&display=swap");

header {
  padding-top: 12rem;
  overflow: hidden;
}
.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
.header__socials {
  display: flex;
  font-size: 2rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  position: relative;
  margin-top: 3rem;
}
.header__socials::after {
  content: "";
  height: 1px;
  width: 2rem;
  background: var(--color-primary);
}
.header__socials::before {
  content: "";
  height: 1px;
  width: 2rem;
  background: var(--color-primary);
}
.me {
  background: linear-gradient(var(--color-primary), transparent);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  height: 15rem;
  width: 15rem;
  padding: 0.5rem;
}
.test {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
}
.texts h1 {
  font-family: "Caveat";
  font-size: 42pt;
}
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .test {
    flex-direction: column;
  }
  .header__socials {
    display: none;
  }
}
